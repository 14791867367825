var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "community__cards" }, [
    _c("div", { staticClass: "o-wrapper" }, [
      _c("div", { staticClass: "o-grid" }, [
        _c("div", { staticClass: "o-grid__item u-width-full" }, [
          _c(
            "div",
            { staticClass: "cards__wrapper", attrs: { id: "cardsWrapper" } },
            [
              _vm.showInitialPlaceholders
                ? _c("content-placeholders")
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "cards",
                  attrs: { id: "cards", "data-cy": "community-cards" },
                },
                [
                  _vm._l(_vm.cards, function (card) {
                    return _c("post-card-api", {
                      key: card.id,
                      attrs: { card: card },
                    })
                  }),
                  _vm._v(" "),
                  _vm._l(8, function (index) {
                    return _vm.showPagingPlaceHolders
                      ? [_c("content-placeholder")]
                      : _vm._e()
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.$root.user !== false && typeof _vm.$root.community != "undefined"
        ? _c("a", {
            staticClass: "c-article__add",
            attrs: { href: "/post/create/" + _vm.$root.community.url },
          })
        : this.$root.user !== false
        ? _c("a", {
            staticClass: "c-article__add",
            attrs: { href: "/post/create/" },
          })
        : _c("a", {
            staticClass: "c-article__add",
            attrs: { "data-cy": "add-article-not-logged-in" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.showMemberPanel.apply(null, arguments)
              },
            },
          }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }